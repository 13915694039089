import { TasticWrapperProps } from 'frontastic';
import { injectDataSources } from 'frontastic/lib/utils/inject-data-sources';
import ErrorBoundary from '../../frontastic/lib/error-boundary';
import { useVisibilityByCMSToggles } from 'hooks/useVisibilityByCMSToggles';

export function ComposableTasticWrapper(props: TasticWrapperProps) {
  const { tastics, data, dataSources, highlight = false } = props;
  const TasticToRender: React.ElementType = tastics[data.tasticType] || tastics['default'];

  if (data.tasticType === 'composable/specialty-cuisines') {
    return null;
  }

  const { mobile, desktop, tablet } = data.configuration;
  const isTasticVisible = useVisibilityByCMSToggles({ mobile, desktop, tablet });
  if (!isTasticVisible) {
    return null;
  }

  // inject all datasources into the proper nodes
  // dataSources null check satisfies TS
  const updatedBlock = dataSources ? injectDataSources(data.configuration, dataSources) : data.configuration;

  return (
    <ErrorBoundary>
      <TasticToRender
        type={data?.tasticType}
        id={data?.tasticId}
        data={updatedBlock}
        pageFolder={props.pageFolder}
        slug={props.slug}
      />
    </ErrorBoundary>
  );
}
