import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { excludedShopCategoriesLabel, LeftSideMenuLabels } from 'composable/components/cms-components';
import { isCatalogSelectedCb } from 'composable/components/cms-components/left-side-menu/utils';
import { ButtonLabel, SideMenuItem } from 'composable/types';
import routes from 'helpers/constants/routes';
import { useFilterAllowedLinks } from '../utils/user-utils';

const linksToButtons = [
  ButtonLabel.LOG_IN_TO_ORDER?.toLowerCase(),
  ButtonLabel.JOIN_TODAY?.toLowerCase(),
  ButtonLabel.LOG_OUT?.toLowerCase(),
];

export const usePropsMenu = (links: SideMenuItem[], isDrawer: boolean = false) => {
  const { asPath: pathToHandle } = useRouter();
  const [openItems, setOpenItems] = useState({});

  const allowedLinks = useFilterAllowedLinks(links);
  const buttons = useMemo(
    () => allowedLinks.filter((link) => linksToButtons.includes(link.label?.toLowerCase())),
    [allowedLinks],
  );

  const finalLinks = useMemo(
    () => allowedLinks.filter((link) => !linksToButtons.includes(link.label?.toLowerCase())),
    [allowedLinks],
  );

  useEffect(() => {
    // Handle the open state for parent items
    const newOpenItems = isDrawer ? { Catalog: true, 'Order Guides': true } : {};
    finalLinks.forEach((item) => {
      if (item.children) {
        const path = pathToHandle?.split('?')[0];
        const firstPartOfItem = item.link?.link?.split('?')[0];
        const firstPartOfPageFolder = item.link?.pageFolder?._url?.split('?')[0];

        const isParentPath = path === firstPartOfItem || path === firstPartOfPageFolder;

        const isCatalogSelected = isCatalogSelectedCb(path);
        const isOrderGuideSelected = path.includes(routes.ORDER_GUIDE);

        if (!isOrderGuideSelected && !isDrawer) {
          newOpenItems[LeftSideMenuLabels.OrderGuides] = false;
        }
        //SHAM-2570
        if (item.label === LeftSideMenuLabels.Catalog && isCatalogSelected) {
          newOpenItems[item.label] = true;
          return;
        }
        const isChildPathSelected = item.children.some((child) => {
          //SHAM-2570
          if (excludedShopCategoriesLabel.includes(child.label as LeftSideMenuLabels) && path === '/') {
            return false;
          }
          //SHAM-2570
          if (child.label === LeftSideMenuLabels.ShopCategories && isCatalogSelected) {
            return true;
          }

          return child.link.link?.split('?')[0] === path || child.link.pageFolder?._url?.split('?')[0] === path;
        });
        if (isParentPath || isChildPathSelected) {
          newOpenItems[item.label] = true;
        }
      }
    });
    setOpenItems({ ...newOpenItems });
  }, [pathToHandle]);

  return { openItems, setOpenItems, buttons, finalLinks };
};
