import { LineItem } from '@Types/cart/LineItem';
import { MediaItem } from 'frontastic';
import { AccordionLink } from 'frontastic/tastics/composable/mega-menu-three-levels-2';

export interface Link {
  label: string;
  reference: PageFolderReference | LinkReference;
}

export interface PageFolderReference {
  type: LinkReferenceType.pageFolder;
  pageFolder: {
    pageFolderId: string;
    name: string;
    _urls: {
      [locale: string]: string;
    };
    _url: string;
  };
  openInNewWindow: boolean;
}

export enum LinkReferenceType {
  link = 'link',
  'pageFolder' = 'page-folder',
}

export interface LinkReference {
  pageFolder: {
    _url: string;
  };
  type: LinkReferenceType.link | LinkReferenceType.pageFolder;
  link: string;
  target?: string;
  openInNewWindow: boolean;
  isPublic?: boolean;
}

export interface MegaMenuThreeLevelsProps {
  links: SideMenuItem[];
  accordionLinks?: AccordionLink[];
}

export interface MegaMenuItem {
  label: string;
  link?: LinkReference;
  children?: MegaMenuItem[];
  icon?: MediaItem;
  type?: 'default' | 'tel' | 'mailto';
}

export interface SideMenuItem {
  menuType: 'default' | 'payBillButton';
  label: string;
  link?: LinkReference;
  icon?: MediaItem;
  children?: SideMenuItemChild[];
  isPublicAvailable?: boolean;
  requiredPermission?: string;
}

export interface SideMenuItemChild {
  label: string;
  link: LinkReference;
}

export interface AccountInformation {
  accountName: string;
  accountNumber: string;
  address: string;
}

export enum ButtonLabel {
  JOIN_TODAY = 'Join Today',
  LOG_IN_TO_ORDER = 'Log In to Order',
  LOG_OUT = 'Log Out',
}

type AppliedFilters = {
  [key: string]: any;
};

export type AddedProductsFromsPLP = {
  appliedFilters: AppliedFilters;
  displayedFilters: AppliedFilters;
  objectIDs: string[];
  queryID: string;
  indexName: string;
};

export type CustomObjectData = {
  productNumber: string;
  brand: string;
  unitPrice: string;
};

export type LineItemsByQueryID = {
  [key: string]: ({
    lineItem: LineItem;
  } & AddedProductsFromsPLP)[];
};
