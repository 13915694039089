import { Link, Text } from '@chakra-ui/react';

export const ChildTitleLink = ({ child }) => {
  return (
    <Link
      href={child.link.link}
      isExternal
      _hover={{ textDecoration: 'none' }}
      _focusWithin={{
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        boxShadow: 'none',
        borderRadius: '2px',
      }}
    >
      <Text fontWeight={700} color="white" fontSize="xs" _hover={{ cursor: 'pointer', color: 'primary.400' }}>
        {child.title}
      </Text>
    </Link>
  );
};
