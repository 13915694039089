import React, { useEffect, useMemo } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Container, Grid as ChakraGrid, GridItem, useBreakpointValue, VStack, Box } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { leftSideMenuWidth } from 'composable/components/cms-components';
import { useComposable } from 'composable/components/composable-provider';
import { ComposableTasticWrapper } from 'composable/components/composable-tastic-wrapper';
import { FullPageLoader } from 'composable/components/fullPageLoader';
import { urlsToHide } from 'composable/components/general';
import { Z_INDEX } from 'composable/components/global-search/constants';
import { useGetOfflineBannerHeight } from 'composable/components/offline-detector/helpers/useGetOfflineBannerHeight';
import { LAYOUT_VALUES } from 'composable/components/pdp/constants';
import { useGetSuperUserBannerSize } from 'composable/components/super-user/helpers/useGetSuperUserBannerHeight';
import { convertToRows, getGridItemSize } from 'composable/helpers/utils/grid-utils';
import { isPublicPage } from 'composable/helpers/utils/is-public-page';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks/useFormat';
import { isSuperUserPage } from 'helpers/slugHelpers';
import { findMultipleTasticsByType } from 'frontastic/lib/utils';
import { Errors } from './errors';
import { FrontasticImage } from './image';
import { CellConfiguration, PageDataResponse, PagePreviewDataResponse, Tastic, TasticRegistry } from './types';

const DynamicMiniCart = dynamic(() => import('composable/components/mini-cart').then((mod) => mod.MiniCart));

const renderTastics = (
  layoutElement: ReturnType<typeof convertToRows>[0][0],
  tastics: TasticRegistry,
  currentHighlight: string | undefined,
  data: PageDataResponse,
  slug: string,
) =>
  layoutElement.tastics.map((t: Tastic) => (
    <ComposableTasticWrapper
      tastics={tastics}
      key={t.tasticId}
      data={t}
      dataSources={data.data.dataSources}
      pageFolder={data.pageFolder}
      highlight={currentHighlight === t.tasticId}
      slug={slug}
    />
  ));

const renderGridItems = (
  row: ReturnType<typeof convertToRows>[0],
  tastics: TasticRegistry,
  getResponsiveGridItemSpanValue: (size: number) => number,
  shouldDisplay: (config: CellConfiguration) => boolean,
  currentHighlight: string | undefined,
  data: PageDataResponse,
  slug: string,
  body: boolean,
) =>
  row.map((layoutElement) => {
    if (!shouldDisplay(layoutElement.configuration)) return null;
    const { displayPageSpacing } = data?.pageFolder?.configuration as PageFolderConfig;

    const pageSpacingProps =
      displayPageSpacing && body
        ? {
            pl: LAYOUT_VALUES.PADDING_LEFT,
            pr: LAYOUT_VALUES.PADDING_RIGHT,
            maxW: 'container.full',
          }
        : {};
    return (
      <GridItem
        key={layoutElement.layoutElementId}
        id={layoutElement.tastics[0]?.tasticId}
        className={layoutElement.tastics[0]?.tasticType}
        gridColumn={`span ${getResponsiveGridItemSpanValue(layoutElement.configuration.size)}`}
        {...pageSpacingProps}
        // sx={{ '> div': { height: '100%' } }}
      >
        {renderTastics(layoutElement, tastics, currentHighlight, data, slug)}
      </GridItem>
    );
  });

interface SectionGridProps {
  as: 'header' | 'main' | 'footer';
  rows: ReturnType<typeof convertToRows>;
  tastics: TasticRegistry;
  getResponsiveGridItemSpanValue: (size: number) => number;
  shouldDisplay: (config: CellConfiguration) => boolean;
  currentHighlight?: string;
  data: PageDataResponse;
  css?: any;
  slug: string;
}

const SectionGrid: React.FC<SectionGridProps> = ({
  as,
  rows,
  tastics,
  getResponsiveGridItemSpanValue,
  shouldDisplay,
  currentHighlight,
  data,
  css,
  slug,
}) => (
  <VStack as={as} spacing={0} {...(css || {})}>
    {rows.map((row, i) => (
      <ChakraGrid key={i} w="full" gap="20px" gridTemplateColumns="repeat(12, 1fr)">
        {renderGridItems(
          row,
          tastics,
          getResponsiveGridItemSpanValue,
          shouldDisplay,
          currentHighlight,
          data,
          slug,
          as === 'main',
        )}
      </ChakraGrid>
    ))}
  </VStack>
);

export type PageFolderConfig = {
  displayPageSpacing?: boolean;
  displayMedia?: FrontasticImage;
  seoTitle?: string;
  seoDescription?: string;
  seoKeywords?: string;
};

export function FrontasticRenderer({
  data,
  tastics = {},

  currentHighlight,
  slug,
}: {
  data: PageDataResponse & PagePreviewDataResponse;
  tastics: TasticRegistry;
  gridClassName?: string;
  wrapperClassName?: string;
  currentHighlight?: string;
  slug: string;
}) {
  const isPublic = isPublicPage(data);
  const { isHideHeader, isScrollingDown } = useGlobal().useOgHeaderScroll;
  const router = useRouter();
  const { isOffline } = useGlobal();
  const topStickyValue = useGetSuperUserBannerSize() + useGetOfflineBannerHeight(isOffline);

  const headerSectionRows = useMemo(
    () => convertToRows(data?.page?.sections?.head?.layoutElements),
    [data.page.sections.head],
  );

  const isEditOrderPage = !!slug.match(/(\/order\/edit\/)\w+/);
  const isLg = useBreakpointValue({ base: false, lg: true });

  const isDesktop = useBreakpointValue({ base: false, xl: true });
  // check if left side nav exist, to apply padding on the page sections
  const hasLeftSideNav = useMemo(
    () =>
      data?.page?.sections?.head?.layoutElements?.map((layout) => {
        if (layout?.tastics[0]?.tasticType === 'composable/left-side-menu') {
          return true;
        }
      }).length > 0,
    [headerSectionRows],
  );

  const mainSectionRows = useMemo(
    () => convertToRows(data?.page?.sections?.main?.layoutElements),
    [data.page.sections.main],
  );
  const footerSectionRows = useMemo(
    () => convertToRows(data?.page?.sections?.footer?.layoutElements),
    [data.page.sections.footer],
  );

  const getResponsiveGridItemSpanValue = useBreakpointValue({
    base: (originalSize: number) => getGridItemSize({ viewport: 'mobile', originalSize }),
    md: (originalSize: number) => getGridItemSize({ viewport: 'tablet', originalSize }),
    lg: (originalSize: number) => getGridItemSize({ viewport: 'desktop', originalSize }),
  });

  const shouldDisplay = useBreakpointValue({
    base: (config: CellConfiguration) => config.mobile,
    md: (config: CellConfiguration) => config.tablet,
    lg: (config: CellConfiguration) => config.desktop,
  });
  const {
    state: { loading: isLoadingUserData },
  } = useGlobal().useUserGlobal;

  const { formatMessage } = useFormat({ name: 'common' });

  const showMiniCart = () => {
    if (isEditOrderPage) {
      return !isLg;
    }

    return !urlsToHide.includes(slug) && !isDesktop;
  };

  const { setAdditionalMenuItems } = useComposable();
  const additionalMenuItems = useMemo(() => findMultipleTasticsByType(data, 'composable/specialty-cuisines'), [data]);

  useEffect(() => {
    if (additionalMenuItems && additionalMenuItems.length) {
      setAdditionalMenuItems(additionalMenuItems);
    }
  }, [additionalMenuItems]);

  const isOGPage = router.asPath.includes(routes.ORDER_GUIDE);
  const isSuperUser = isSuperUserPage(router.asPath);
  const hideHeader = isHideHeader && isScrollingDown && isOGPage;

  return isLoadingUserData ? (
    <FullPageLoader />
  ) : (
    <>
      <Head>
        <title>{formatMessage({ id: 'app.title' })}</title>
      </Head>
      <Container
        position="relative"
        maxWidth={'full'}
        padding={0}
        bg={isSuperUser ? 'white' : 'blackGradient'}
        backgroundColor={isSuperUser ? 'white' : 'black'}
        minHeight="100vh"
        display="flex"
        flexDirection="column"
      >
        {process && process.env.NODE_ENV !== 'production' && <Errors />}

        <SectionGrid
          as="header"
          rows={headerSectionRows}
          tastics={tastics}
          getResponsiveGridItemSpanValue={getResponsiveGridItemSpanValue}
          shouldDisplay={shouldDisplay}
          currentHighlight={currentHighlight}
          data={data}
          css={{
            position: 'sticky',
            top: `${topStickyValue}px`,
            width: '100%',
            backdropFilter: hideHeader ? 'blur(0px)' : 'blur(4px)',
            zIndex: Z_INDEX.HEADER,
            paddingLeft: { xl: hasLeftSideNav ? leftSideMenuWidth : 0 },
          }}
          slug={slug}
        />

        <Box flex={1} overflow="auto">
          <SectionGrid
            as="main"
            rows={mainSectionRows}
            tastics={tastics}
            getResponsiveGridItemSpanValue={getResponsiveGridItemSpanValue}
            shouldDisplay={shouldDisplay}
            currentHighlight={currentHighlight}
            data={data}
            css={{ paddingLeft: { xl: hasLeftSideNav ? leftSideMenuWidth : 0 } }}
            slug={slug}
          />
          {showMiniCart() && !isPublic && (
            <Box position="fixed" bottom="0" width="100vw" zIndex={2} pt={4} px={{ base: '16px', md: '88px' }}>
              <DynamicMiniCart />
            </Box>
          )}
        </Box>
        <SectionGrid
          as="footer"
          rows={footerSectionRows}
          tastics={tastics}
          getResponsiveGridItemSpanValue={getResponsiveGridItemSpanValue}
          shouldDisplay={shouldDisplay}
          currentHighlight={currentHighlight}
          data={data}
          css={{ paddingLeft: { xl: hasLeftSideNav ? leftSideMenuWidth : 0 } }}
          slug={slug}
        />
      </Container>
    </>
  );
}
