import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Divider, Flex, Skeleton, useBreakpointValue, DrawerContentProps, useToken, Box } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { Header } from 'composable/components/cms-components/shop-categories-left-side-menu/header';
import { Item } from 'composable/components/cms-components/shop-categories-left-side-menu/item';
import { useComposable } from 'composable/components/composable-provider';
import { useGetCategories } from 'composable/helpers/hooks/useGetCategories';
import { CategoryHierarchyItem } from 'composable/helpers/utils/build-category-hierarchy';
import { useFormat } from 'helpers/hooks/useFormat';
import { AdditionalMenuItems } from './additional-menu-items';

const shopCategoriesDrawerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  WebkitBackdropFilter: 'blur(3px)',
  backdropFilter: 'blur(3px)',
  height: 'calc(100vh - calc(100vh - 100%))',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  backgroundColor: 'rgba(47, 48, 55, 0.90)',
};

const shoppingCategoriesCss: DrawerContentProps = {
  width: '100%',
  pt: { base: 8, lg: 10 },
  px: { base: 8, lg: 10 },
  pb: 16,
  tabIndex: -1,
  className: 'left-side-menu',
  id: 'drawer-content-shop-categories-menu',
};

interface BreadcrumbItem {
  name: string;
  children: CategoryHierarchyItem[];
}

interface State {
  currentItems: CategoryHierarchyItem[];
  selectedShoppingCategory: string;
}

export const ShopCategories = () => {
  const router = useRouter();
  const { megaDrawer, additionalMenuItems } = useComposable();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { formatMessage } = useFormat({ name: 'common' });
  const shopCategoriesLabel = formatMessage({ id: 'label.shopCategories' });
  const { closeShoppingCategories } = useGlobal().useCheckoutGlobal;
  const { categories, isLoading } = useGetCategories();
  const neutral300 = useToken('colors', 'neutral.300');
  const neutral650 = useToken('colors', 'neutral.650');

  const [state, setState] = useState<State>({
    currentItems: [],
    selectedShoppingCategory: shopCategoriesLabel,
  });
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItem[]>([
    { name: state.selectedShoppingCategory, children: [] },
  ]);

  useEffect(() => {
    if (categories?.length > 0 && !isLoading) {
      setState((prev) => ({ ...prev, currentItems: categories }));
      setBreadcrumb([{ name: state.selectedShoppingCategory, children: categories }]);
    }
  }, [isLoading, categories]);

  const handleNext = (item: CategoryHierarchyItem, parent: BreadcrumbItem) => {
    if (item.children.length > 0) {
      setState((prev) => ({ ...prev, currentItems: item.children, selectedShoppingCategory: item.name }));
      setBreadcrumb([...breadcrumb, item]);
    } else if (parent) {
      setState((prev) => ({ ...prev, currentItems: parent.children, selectedShoppingCategory: parent.name }));
    }
  };

  const handleBack = () => {
    const newBreadcrumb = breadcrumb.slice(0, breadcrumb.length - 1);
    setBreadcrumb(newBreadcrumb);
    setState((prev) => ({
      ...prev,
      currentItems: newBreadcrumb[newBreadcrumb.length - 1].children,
      selectedShoppingCategory: newBreadcrumb[newBreadcrumb.length - 1].name,
    }));
  };

  const handleOnSelect = (item: CategoryHierarchyItem) => {
    if (item.children.length === 0) {
      router.push(item.url);
      if (isMobile) {
        megaDrawer.onClose();
      }
      handleClose();
      return;
    }
    handleNext(item, breadcrumb[breadcrumb.length - 1]);
  };

  const handleClose = () => {
    setBreadcrumb([{ name: shopCategoriesLabel, children: categories }]);
    setState((prev) => ({
      ...prev,
      currentItems: categories,
      selectedShoppingCategory: shopCategoriesLabel,
    }));
    closeShoppingCategories();
  };

  const onOpenAdditionalMenuItems = useCallback((title) => {
    setState((prev) => ({
      ...prev,
      currentItems: [],
      selectedShoppingCategory: title,
    }));
  }, []);

  const onCloseAdditionalMenuItems = () => {
    setState((prev) => ({
      ...prev,
      currentItems: categories,
      selectedShoppingCategory: shopCategoriesLabel,
    }));
  };

  const isDefaultLabel = state.selectedShoppingCategory === shopCategoriesLabel;

  const isAdditionalMenuItemLabel = additionalMenuItems?.some(
    (item) => item.configuration.title === state.selectedShoppingCategory,
  );

  const findSelectedMenuItems = (title: string) => {
    return additionalMenuItems?.find((item) => item.configuration.title === title).configuration.children;
  };

  return (
    <>
      <ShopCategories.Header
        category={state.selectedShoppingCategory}
        onClose={() => {
          if (isMobile) {
            megaDrawer.onClose();
          }
          handleClose();
        }}
        onClick={() => {
          if (isAdditionalMenuItemLabel) {
            onCloseAdditionalMenuItems();
            return;
          }
          if (isDefaultLabel) {
            handleClose();
            return;
          }
          handleBack();
        }}
      />
      <Divider borderColor="neutral.700" my={6} />
      <Flex
        as="ul"
        gap={{ base: 7, lg: 3 }}
        flexDir="column"
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
            backgroundColor: neutral650,
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: neutral300,
            borderRadius: '4px',
          },
        }}
      >
        {isLoading
          ? Array.from({ length: 12 }, (_, index) => (
              <Skeleton
                h={8}
                minH={8}
                w="full"
                cursor="wait"
                key={`skeleton_list_item_${index}`}
                borderRadius="2px"
                endColor="neutral.600"
                startColor="neutral.700"
              />
            ))
          : state.currentItems.map((item) => (
              <ShopCategories.Item
                key={item.id}
                label={item.name}
                children={item.children}
                url={item.url}
                onClick={() => handleOnSelect(item)}
              />
            ))}
      </Flex>
      {isLoading && additionalMenuItems?.length > 0 && (
        <>
          <Divider borderColor="neutral.700" my={6} />
          {additionalMenuItems?.map(() => (
            <Skeleton
              borderRadius="2px"
              endColor="neutral.600"
              startColor="neutral.700"
              cursor="wait"
              minH={8}
              h={8}
              mt={2}
              w="full"
            />
          ))}
        </>
      )}
      {!isLoading && isDefaultLabel && additionalMenuItems?.length > 0 && (
        <>
          <Divider borderColor="neutral.700" my={6} />
          {additionalMenuItems?.map((item) => (
            <>
              <AdditionalMenuItems.Button
                onOpen={() => onOpenAdditionalMenuItems(item.configuration.title)}
                title={item.configuration.title}
              />
              <Box mt={2} />
            </>
          ))}
        </>
      )}
      {!isLoading && !isDefaultLabel && additionalMenuItems?.length > 0 && isAdditionalMenuItemLabel && (
        <AdditionalMenuItems.List>
          {findSelectedMenuItems(state.selectedShoppingCategory).map((item) => (
            <AdditionalMenuItems.Item key={item.title} {...item} />
          ))}
        </AdditionalMenuItems.List>
      )}
    </>
  );
};

ShopCategories.Item = Item;

ShopCategories.Header = Header;

ShopCategories.DrawerStyle = shopCategoriesDrawerStyle;

ShopCategories.DrawerCSS = shoppingCategoriesCss;
