import { SUPER_USER_BANNER_ID } from '../constants';
import { useGetElementSize } from 'composable/helpers/hooks';
import { useGlobal } from 'components/globalProvider';

export const useGetSuperUserBannerSize = () => {
  const {
    state: { loggedAsSuperUser = false },
  } = useGlobal().useUserGlobal;
  const { height } = useGetElementSize(SUPER_USER_BANNER_ID, !loggedAsSuperUser);
  return height;
};
