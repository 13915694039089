import { useState, useEffect } from 'react';

const LIST_HEIGHT_BASE = 380; // Must be hard coded to match the height of the list

export const useHandleHeightShift = (listRef: React.MutableRefObject<any>) => {
  const [listHeight, setListHeight] = useState(0);
  const difference = listHeight - LIST_HEIGHT_BASE;

  useEffect(() => {
    const handleResize = (entries) => {
      for (let entry of entries) {
        setListHeight(entry.contentRect.height);
      }
    };

    const observer = new ResizeObserver(handleResize);

    if (listRef.current) {
      observer.observe(listRef.current);
    }

    return () => {
      if (listRef.current) {
        observer.unobserve(listRef.current);
      }
    };
  }, []);

  return { difference };
};
