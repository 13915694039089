import React, { FunctionComponent, PropsWithChildren } from 'react';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { Box, Flex, Icon, useToken, Text } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { useComposable } from 'composable/components/composable-provider';
import { Reference, getReferenceTarget } from 'helpers/reference';
import { CaretRight } from 'phosphor-react';
import { MediaItem } from 'frontastic';

export interface AdditionalMenuItemChild {
  title: string;
  description: string;
  link?: Reference;
  image: Image;
}

export interface Image {
  title: Title;
  media: MediaItem;
  en_US: string;
}

interface Title {
  [key: string]: string;
}

const Button = ({ onOpen, title }) => {
  const primary300 = useToken('colors', 'primary.300');

  return (
    <Flex
      maxH="8"
      minH="8"
      w="fit-content"
      as="button"
      alignItems="center"
      color="neutral.200"
      fontWeight="normal"
      lineHeight="19.2px"
      _hover={{ color: 'primary.500' }}
      _focusVisible={{ outline: `1px solid ${primary300}`, borderRadius: '4px' }}
      onClick={onOpen}
    >
      {title} <Icon w={5} h={5} as={CaretRight} />
    </Flex>
  );
};

const List: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <Flex as="ul" gap={5} flexDir="column">
      {children}
    </Flex>
  );
};

const Item = (item: AdditionalMenuItemChild) => {
  const { megaDrawer } = useComposable();
  const { closeShoppingCategories } = useGlobal().useCheckoutGlobal;
  const router = useRouter();
  const primary300 = useToken('colors', 'primary.300');
  const linkPath = item?.link ? getReferenceTarget(item.link as Reference) : `/search?query=${item.title}`;
  const externalLinkProps = item?.link?.openInNewWindow ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  const onSelect = () => {
    router.push(linkPath);
    closeShoppingCategories();
    megaDrawer.onClose();
  };

  return (
    <Flex
      as="a"
      gap={4}
      tabIndex={1}
      onClick={onSelect}
      cursor="pointer"
      _focusVisible={{ outline: `1px solid ${primary300}`, borderRadius: '4px' }}
      {...externalLinkProps}
    >
      {item?.image && item?.image?.media?.file && (
        <Box h="88px" w="88px" minH="88px" minW="88px" position="relative" borderRadius="4px">
          <NextImage src={item?.image?.media?.file} alt={item?.image?.media?.alt} objectFit="contain" layout="fill" />
        </Box>
      )}
      <Flex gap={0.5} flexDir="column">
        <Flex h="8" mt={0.5} minH="8" alignItems="center">
          <Text color="neutral.200" _hover={{ color: 'primary.500' }}>
            {item.title}
          </Text>
        </Flex>
        <Text color="neutral.300" fontSize="sm" lineHeight="21px">
          {item.description}
        </Text>
      </Flex>
    </Flex>
  );
};

export const AdditionalMenuItems = {
  List,
  Button,
  Item,
};
