import { useMemo } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

export const useVisibilityByCMSToggles = (CMSToggles: { tablet: boolean; desktop: boolean; mobile: boolean }) => {
  const deviceType = useBreakpointValue({ base: 'mobile', md: 'tablet', lg: 'desktop' });

  const isVisible = useMemo(() => {
    return Boolean(CMSToggles[deviceType]);
  }, [CMSToggles, deviceType]);

  return isVisible;
};
