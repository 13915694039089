import { SHOP_PURCHASE_HISTORY, TEN_KEY, useLocalStorageOrderGuide } from 'composable/components/order-guide/helpers';
import { LeftSideMenuLabels } from '..';

// Tech-debt: SHAM-2570
export const useOrderGuideChildItems = (label: string) => {
  const {
    ogLocalStorageValues: { selectedOrderGuide },
  } = useLocalStorageOrderGuide();

  //SHAM-2570
  const isPurchaseHistorySelected =
    selectedOrderGuide === SHOP_PURCHASE_HISTORY && label === LeftSideMenuLabels.PurchaseHistory;
  const ogChildItems = [LeftSideMenuLabels.PurchaseHistory, LeftSideMenuLabels.TenKey] as string[];
  const is10KeySelected = selectedOrderGuide === TEN_KEY && label === LeftSideMenuLabels.TenKey;

  const ogChildItemSelected = isPurchaseHistorySelected || is10KeySelected;

  return { ogChildItems, ogChildItemSelected };
};
