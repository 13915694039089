import { Image, ImageProps, useBreakpointValue } from '@chakra-ui/react';
import { useFormat } from 'helpers/hooks/useFormat';

interface LogoProps {
  isHideOnPage: boolean;
  imageProps?: ImageProps;
}

export const Logo = ({ isHideOnPage, imageProps }: LogoProps) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const isMobile = useBreakpointValue({ base: true, xl: false });

  return (
    <Image
      cursor="pointer"
      src="/images/shamrock-logo.svg"
      alt={formatMessage({ id: 'navigation.logo.altText' })}
      minWidth={{ xl: '94px', base: '64px' }}
      transform={
        !isHideOnPage
          ? !isMobile
            ? 'translateY(-12px)'
            : 'translateY(0px)'
          : !isMobile
          ? 'translateY(5px)'
          : 'translateY(0px)'
      }
      {...imageProps}
    />
  );
};
