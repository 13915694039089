import { Link, Text } from '@chakra-ui/react';
import { AccordionLink } from 'frontastic/tastics/composable/mega-menu-three-levels-2';
import NextLink from 'next/link';
import { handleGetHref, checkLinkType } from './utils';

export const ChildLink = ({ childItem }: { childItem: AccordionLink['children'][0] }) => {
  const href = handleGetHref(childItem);
  return (
    <Link
      as={NextLink}
      href={checkLinkType(childItem, href)}
      passHref
      _focusWithin={{
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        boxShadow: 'none',
        borderRadius: '2px',
      }}
    >
      <Text
        as="a"
        target={childItem.link && childItem.link.link && childItem.link.openInNewWindow ? '_blank' : '_self'}
        fontWeight={400}
        color="white"
        cursor="pointer"
        fontSize="xs"
        mb={2}
        _focusWithin={{
          outlineColor: 'violet.400',
          outlineWidth: '2px',
          outlineStyle: 'solid',
          boxShadow: 'none',
          borderRadius: '2px',
        }}
      >
        {childItem?.content}
      </Text>
    </Link>
  );
};
